import React, { Suspense, lazy } from "react";
import "./App.css"; 
import Loader from "./Components/Loader/Loader";
const Home = lazy(() => import("./Components/Home/Home"));
const Navbar = lazy(() => import("./Components/Navbar/Navbar"));
const Service = lazy(() => import("./Components/Service/Service"));
const About = lazy(() => import("./Components/About/About"));
const Portfolio = lazy(() => import("./Components/Portfolio/Portfolio"));
const Contact = lazy(() => import("./Components/Contact/Contact"));
const Footer = lazy(() => import("./Components/Footer/Footer"));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
     
        <Navbar />
        <Home />
        <About />
        <Portfolio /> 
        <Service />
        <Contact />
        <Footer />
      </Suspense>
    </div>
  );
}

// function ParticlesF({ children }) {
//   return (
//     <div style={{ position: "relative" }}>
      
//       {children && <div style={{ position: "relative" }}>{children}</div>}
//     </div>
//   );
// }

export default App;
